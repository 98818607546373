import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import APIServices from "../../../httpServices/httpServices";
import DeleteModal from "../../common/modals/DeleteModal";
import { emitSuccessToast } from "../../common/toast/ToastContainer";
import DataView from "../../common/viewDetail/DataView";
import WritingResponseList from "../writingResponse/WritingResponseList";


const ViewWritingQuestion = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [showModal, setShowModal] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        if (id) {
            getDetailView();
        }
        // eslint-disable-next-line
    }, [id]);

    // Fetch User Data
    const getDetailView = async () => {
        const { data, success } = await new APIServices(
            `writing-question/${id}`
        ).get();
        if (success) {
            setData(data);
        }
    };


    const handleShowDeleteModal = (id) => {
        setShowModal(true);
    };

    const handleDeleteRole = async () => {
        const { message, success } = await new APIServices(
            `writing-question/${id}`
        ).delete();
        if (success) {
            setShowModal(false);
            emitSuccessToast(message);
            navigate("/writing-question");
        }
    };

    const {
        _id,
        questionType,
        scenario,
        question,
        instructions,
        options,
        wordLimit,
        timeLimit,
        explanation,
        status,
        orderBy,
    } = data;

    return (
        <>
            <div className="max-w-6xl px-4 py-4 mx-auto lg:py-8 md:px-6">
                <div className="flex justify-between mt-5 mb-4 items-end">
                    <h2 className="font-bold text-lg"> Writing Question Information </h2>
                </div>
                <div className="flex items-center justify-between">
                    <DataView label="question" value={question} />
                    <div className="flex items-center">
                        <div
                            className={`rounded-full w-3 h-3 bg-green-700 mr-2 ${status === "Active" ? "bg-green-700" : "bg-red-700"
                                }`}
                        ></div>
                        <span className="text-sm"> {status} </span>
                    </div>
                    {status === "Inactive" && (
                        <button
                            onClick={() => handleShowDeleteModal(_id)}
                            className=" flex items-center px-4 py-2.5 bg-red-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-600"
                        >
                            Delete
                        </button>
                    )}
                </div>

                <div className="shadow bg-white border-gray-500 p-8 rounded">
                    <div className="flex flex-wrap">
                        <DataView label="scenario" value={scenario} />
                        <DataView label="questionType" value={questionType} />
                        <DataView label="instructions" value={instructions} />
                        <DataView label="wordLimit" value={wordLimit} />
                        <DataView label="timeLimit" value={timeLimit} />
                        <DataView label="explanation" value={explanation} />
                        <DataView label="orderBy" value={orderBy} />
                        {questionType === "EssayWriting" && (
                            <div className="flex flex-wrap">
                                {options.map((option, index) => (
                                    <div key={option._id} className="flex items-center gap-2 mb-2">
                                        {option.text}
                                    </div>
                                ))}
                            </div>
                        )}

                    </div>
                </div>

                {showModal && (
                    <DeleteModal handleDelete={handleDeleteRole} close={setShowModal} />
                )}

                {/* <WritingResponseList writingQuestionId={_id} /> */}

            </div>
        </>
    );
};

export default ViewWritingQuestion;
