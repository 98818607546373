import DOMPurify from 'dompurify';
import React from 'react'
import { Link, useLocation } from 'react-router-dom';

function DropNavLink({ path, title, svg }) {
    const { pathname } = useLocation();

    return (
        <li>
            <Link
                to={path}
                className={`${pathname.split("/")[1] === path ? "bg-primary text-white" : ""
                    } flex h-7 px-2 min-w-0 -translate-x-px items-center gap-2 overflow-hidden rounded-md text-sidebar-foreground outline-none ring-sidebar-ring hover:bg-sidebar-accent hover:text-sidebar-accent-foreground focus-visible:ring-2 active:bg-sidebar-accent active:text-sidebar-accent-foreground disabled:pointer-events-none disabled:opacity-50 aria-disabled:pointer-events-none aria-disabled:opacity-50 [&>span:last-child]:truncate [&>svg]:size-4 [&>svg]:shrink-0 [&>svg]:text-sidebar-accent-foreground data-[active=true]:bg-sidebar-accent data-[active=true]:text-sidebar-accent-foreground text-sm group-data-[collapsible=icon]:hidden`}
            >
                <span
                    className="mr-2 "
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(svg) }}
                />
                <span> {title} </span>
            </Link>
        </li>
    )
}

export default DropNavLink