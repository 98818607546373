import React from "react";
import {
  flexRender,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
} from "@tanstack/react-table";
import { Box } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { createTheme } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";


import { Table } from "@mui/material";

const theme = createTheme({
  palette: {
    mode: "light",
  },
  borderColor: {
    light: "1px solid #f1f1f1",
    dark: "1px solid #eee",
  },
});

function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}) {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);
    return () => clearTimeout(timeout);
  }, [value, debounce]);

  return (
    <input
      {...props}
      value={value || ""}
      onChange={(e) => setValue(e.target.value)}
      className="border p-2 rounded"
    />
  );
}

function Filter({ column, onFilterChange }) {
  const columnFilterValue = column.getFilterValue();

  if (column.columnDef.meta?.disableFilter) return null;

  return (
    <DebouncedInput
      type="text"
      value={columnFilterValue || ""}
      onChange={(value) => {
        column.setFilterValue(value);
        // Only call onFilterChange if the input has at least 4 characters or is empty
        if (value.length >= 4 || value === "") {
          onFilterChange(column.id, value);
        }
      }}
      // placeholder={`Search ${column.columnDef.header}`}
      placeholder={`Search`}
      style={{ width: "100%" }}
    />
  );
}

export default function DataTable({ columns, data, onFilterChange }) {
  const theme = useTheme();
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(), // Enable client-side sorting
    getFilteredRowModel: getFilteredRowModel(),
  });

  return (
    <Box
      sx={{
        width: "100%",
        bgcolor: "background.default",
        color: "text.primary",
      }}
      className="overflow-x-auto w-full">
      <Table
        aria-labelledby="tableTitle"
        stickyHeader
        hoverRow
        sx={{
          "--TableCell-headBackground": "var(--joy-palette-background-level1)",
          "--Table-headerUnderlineThickness": "1px",
          "--TableRow-hoverBackground": "var(--joy-palette-background-level1)",
          "--TableCell-paddingY": "4px",
          "--TableCell-paddingX": "8px",
        }}
        style={{
          borderRadius: "10px",
          border: `1px solid ${theme.palette.divider}`,
          overflowX: "auto",
          borderRadius: "10px",
        }}
        className="table-auto border-collapse  w-full rounded-md ">
        <thead
          style={{ borderBottom: `1px solid ${theme.palette.divider}` }}
          sx={{ borderRadius: "10px", bgcolor: "#f1f1f1" }}>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className="font-normal text-sm p-4"
                  style={{
                    backgroundColor: `${theme.palette.background.level2}`,
                    borderRight: `1px solid ${theme.palette.divider}`,
                  }}>
                  <div className="flex items-left ">
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                    {header.column.getCanSort() && (
                      <button
                        onClick={header.column.getToggleSortingHandler()}
                        className="ml-2">
                        {header.column.getIsSorted() === "asc" ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </button>
                    )}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  style={{
                    textAlign: "left",
                    width: 120,
                    padding: "10px 20px",
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    borderRight: `1px solid ${theme.palette.divider}`,
                    fontSize: "0.8rem",
                    backgroundColor: `${theme.palette.background.primary}`,
                  }}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </Box>
  );
}
