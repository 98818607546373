import * as Yup from "yup";

const WritingQuestionValidationSchema = Yup.object().shape({
    writingGroupId: Yup.string().required("Writing Group ID is required"),
    questionType: Yup.string().required("Question Type is required"),
    scenario: Yup.string().required("Scenario is required"),
    question: Yup.string().required("Question is required"),
    instructions: Yup.string(),
    options: Yup.array()
        .when("questionType", {
            is: "EssayWriting",
            then: Yup.array()
                .of(
                    Yup.object().shape({
                        text: Yup.string().required("Option text is required"),
                    })
                )
                .min(2, "Exactly two options are required")
                .max(2, "Exactly two options are required"),
            otherwise: Yup.array().max(0, "Options must be empty for EmailWriting"),
        }),
    wordLimit: Yup.number().required("Word Limit is required").min(1, "Word Limit must be at least 1"),
    timeLimit: Yup.number().required("Time Limit is required").min(1, "Time Limit must be at least 1"),
    explanation: Yup.string(),
    status: Yup.boolean(),
});

export default WritingQuestionValidationSchema;