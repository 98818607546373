import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import APIServices from "../../../httpServices/httpServices";
import DashCard from "../../common/DashCard";
import FormHeading from "../../common/FormHeading";
import SaveButton from "../../common/button/SaveButton";
import FormikSwitchField from "../../common/form/FormikSwitchField";
import FormikTextField from "../../common/form/FormikTextField";
import { emitErrorToast, emitSuccessToast } from "../../common/toast/ToastContainer";
import WritingQuestionValidationSchema from "../../../validation/WritingQuestionValidation";
import { v4 as uuidv4 } from "uuid"; // For generating unique IDs for options
import FormikRichTextEditor from "../../common/form/FormikRichTextEditor";

const WritingQuestionForm = ({ editData }) => {
    const { groupId, questionType } = useParams(); // These will be undefined in edit mode
    const navigate = useNavigate();

    // Determine the orderBy value based on questionType (for creation) or editData (for editing)
    const orderByValue = editData ? editData.orderBy : questionType === "EmailWriting" ? 1 : 2;

    // Initialize options based on questionType (for creation) or editData (for editing)
    const initialOptions = editData
        ? editData?.options?.map(option => ({
            _id: option._id, // Use existing _id or generate a new one
            text: option.text || option // Handle both object and string formats
        }))
        : questionType === "EssayWriting"
            ? [
                { text: "" }, // Initialize with two empty options
                { text: "" },
            ]
            : [];

    const initialValues = {
        writingGroupId: editData ? editData.writingGroupId : groupId, // Use editData if available
        questionType: editData ? editData.questionType : questionType, // Use editData if available
        orderBy: orderByValue, // Set orderBy based on the scenario
        scenario: editData ? editData.scenario : "",
        question: editData ? editData.question : "",
        instructions: editData ? editData.instructions : "",
        options: initialOptions, // Initialize with options based on the scenario
        wordLimit: editData ? editData.wordLimit : 200,
        timeLimit: editData ? editData.timeLimit : 30,
        explanation: editData ? editData.explanation : "",
        status: true
    };

    const [form, setForm] = useState({ ...initialValues });

    useEffect(() => {
        if (editData) {
            // Ensure options have unique IDs if they exist
            const optionsWithIds = editData?.options?.map(option => ({
                _id: option._id || uuidv4(), // Use existing _id or generate a new one
                text: option.text || option // Handle both object and string formats
            }));
            setForm((prev) => ({
                ...prev,
                ...editData,
                options: optionsWithIds,
            }));
        }
    }, [editData]);

    const handleFormikFormSubmit = async (values, actions) => {
        let { setSubmitting, resetForm } = actions;

        // Ensure options are in the correct format
        const formattedOptions = values.options.map(option => ({
            text: option.text || option // Handle both object and string formats
        }));
        let payload = {
            ...values,
            options: formattedOptions,
            status: values.status ? "Active" : "Inactive",
        };

        const { success, data, message } = !!!editData
            ? await new APIServices(`writing-question`).post(payload)
            : await new APIServices(`writing-question/${editData._id}`).put(payload);

        if (success) {
            emitSuccessToast(message);
            setSubmitting(false);
            resetForm();
            navigate(`/writing-group/view/${data?.writingGroupId}`);
        } else {
            emitErrorToast(message);
        }
    };

    const handleOptionChange = (index, value, setFieldValue, values) => {
        const updatedOptions = [...values.options];
        updatedOptions[index].text = value;
        setFieldValue("options", updatedOptions);
    };

    return (
        <section className="py-16 bg-gray-100 dark:bg-gray-800">
            <div className="max-w-6xl px-4 mx-auto">
                <Formik
                    initialValues={form}
                    validationSchema={WritingQuestionValidationSchema}
                    onSubmit={handleFormikFormSubmit}
                    enableReinitialize
                >
                    {(formik) => (
                        <Form>
                            <div className="grid gap-4">
                                <div>
                                    <FormHeading title={"Writing Question Information"} />
                                    <DashCard>
                                        {/* Disabled Order By */}
                                        <FormikTextField
                                            label="Question No."
                                            name="orderBy"
                                            value={formik.values.orderBy}
                                            disabled
                                            formik={formik}
                                        />
                                        {/* Disabled Question Type */}
                                        <FormikTextField
                                            label="Question Type"
                                            name="questionType"
                                            value={formik.values.questionType}
                                            disabled
                                            formik={formik}
                                        />
                                        <FormikTextField
                                            label="Scenario"
                                            name="scenario"
                                            placeholder="Enter the scenario"
                                            formik={formik}
                                            as="textarea"
                                            rows={10}
                                        />

                                        <FormikTextField
                                            label="Question"
                                            name="question"
                                            placeholder="Enter the question"
                                            formik={formik}
                                            as="textarea"
                                        />

                                        {formik.values.questionType === "EmailWriting" && (
                                            // <FormikTextField
                                            //     label="Instructions"
                                            //     name="instructions"
                                            //     placeholder="Enter instructions"
                                            //     formik={formik}
                                            //     as="textarea"
                                            // />
                                            <FormikRichTextEditor
                                                label="Instructions"
                                                name="instructions"
                                                placeholder="Enter instructions"
                                                formik={formik}
                                            />
                                        )}

                                        {/* Show options only if questionType is EssayWriting */}
                                        {formik.values.questionType === "EssayWriting" && (
                                            <div className="mb-4">
                                                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                                    Options
                                                </label>
                                                {formik.values.options.map((option, index) => (
                                                    <div key={index} className="flex items-center gap-2 mb-2">
                                                        <input
                                                            type="text"
                                                            value={option.text}
                                                            onChange={(e) =>
                                                                handleOptionChange(index, e.target.value, formik.setFieldValue, formik.values)
                                                            }
                                                            className="w-full p-2 border rounded"
                                                            placeholder={`Option ${index + 1}`}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        )}

                                        <FormikTextField
                                            label="Word Limit"
                                            name="wordLimit"
                                            type="number"
                                            placeholder="Enter word limit"
                                            formik={formik}
                                        />

                                        <FormikTextField
                                            label="Time Limit (in minutes)"
                                            name="timeLimit"
                                            type="number"
                                            placeholder="Enter time limit"
                                            formik={formik}
                                        />

                                        <FormikTextField
                                            label="Explanation"
                                            name="explanation"
                                            placeholder="Enter explanation"
                                            formik={formik}
                                            as="textarea"
                                        />

                                        <FormikSwitchField label="Status" name="status" />
                                    </DashCard>
                                </div>
                            </div>
                            <SaveButton isSubmitting={formik.isSubmitting} />
                        </Form>
                    )}
                </Formik>
            </div>
        </section>
    );
};

export default WritingQuestionForm;