import React, { useState, useEffect, useCallback } from "react";
import APIServices from "../../../httpServices/httpServices";
import { Link, useNavigate, useParams } from "react-router-dom";
import { emitSuccessToast } from "../../common/toast/ToastContainer";
import DataView from "../../common/viewDetail/DataView";
import DeleteModal from "../../common/modals/DeleteModal";
import CustomImage from "../../common/CustomImage";
import moment from "moment";
import EditIcon from "../../svg/EditIcon";

const ViewUser = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    if (id) {
      getUserDetailView();
    }
    // eslint-disable-next-line
  }, []);

  // Fetch User Data
  const getUserDetailView = useCallback(async () => {
    const { data, success } = await new APIServices(`admin-user/${id}`).get();
    if (success) {
      setUserData(data);
    }
  }, [id]);

  const handleShowDeleteModal = (id) => {
    setShowModal(true);
  };

  const handleDeleteUser = async () => {
    const { message, success } = await new APIServices(`admin-user/${id}`).delete();
    if (success) {
      setShowModal(false);
      emitSuccessToast(message);
      navigate("/admin-users");
    }
  };

  const {
    _id,
    address,
    aliasName,
    email,
    name,
    gender,
    phone,
    profileImage,
    remarks,
    role,
    status,
    userType,
  } = userData;

  const handleEdit = (id) => {
    navigate(`/admin-users/edit/${id}`);
  };

  return (
    <div className="max-w-6xl px-4 py-4 mx-auto lg:py-8 md:px-6">
      <div className="flex gap-x-4">
        <div className="user-profile w-[180px] h-[180px]">
          <CustomImage
            className="employee-avatar object-cover h-full w-full rounded-md"
            source={!!profileImage ? profileImage : ""}
          />
        </div>

        <div className="flex-1">
          <div className="flex gap-2">
            <h1 className="text-2xl font-semibold text-gray-800">{name}</h1>
            <div>
              <button
                className="px-3 py-2 text-xs font-medium text-white bg-primary rounded hover:bg-secondary"
                onClick={() => handleEdit(_id)}
              >
                <span className="mr-2">
                  <EditIcon />
                </span>
              </button>
            </div>
          </div>
          <p className="text-sm text-gray-500">{email}</p>

          <div className="grid grid-cols-3 gap-x-2">
            {/* <div className="flex flex-col">
              <span className="block mb-0 text-sm text-gray-600 font-normal">

                Date of Birth
              </span>
              <span className="font-bold">
                {moment(DOB, moment.ISO_8601).format("LL")}
              </span>
            </div> */}

            <div className="flex flex-col">
              <span className="block mb-0 text-sm text-gray-600 font-normal">
                Contact Number
              </span>
              <span className="mb-5 text-base font-semibold text-gray-800">
                {phone ? phone : "--"}{" "}
              </span>
            </div>


          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <div
                className={`rounded-full w-3 h-3 bg-green-700 mr-2 ${status === "Active" ? "bg-green-700" : "bg-red-700"
                  }`}
              ></div>
              <span className="text-sm"> {status} </span>
            </div>

            {status === "Inactive" && (
              <button
                onClick={() => handleShowDeleteModal(_id)}
                className="flex items-center px-4 py-2.5 bg-red-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-600 mb-5"
              >
                Delete
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="mt-2 mb-2">
        <Link
          to="/admin-users/change-password"
          state={{ userId: _id }}
          className="px-3 py-2 text-xs font-medium text-white bg-primary rounded hover:bg-secondary"
        >
          Change Password
        </Link>
      </div>
      {/* Personal Information */}
      <h2 className="text-lg font-semibold text-gray-800">
        Personal Information
      </h2>
      <div className="p-6 bg-white shadow rounded-lg">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          <DataView label="AliasName" value={aliasName} />
          <DataView label="Gender" value={gender} />
          <DataView label="Role" value={role} />
          <DataView label="UserType" value={userType} />
          <DataView label="Address" value={address} />
          <DataView label="Remarks" value={remarks} />
        </div>
      </div>

      {showModal && (
        <DeleteModal handleDelete={handleDeleteUser} close={setShowModal} />
      )}
    </div>
  );
};

export default ViewUser;
