import { Link, useLocation } from "react-router-dom";

const WritingResponseTabs = ({ }) => {
    const location = useLocation();

    return (
        <div className="flex border-b border-gray-200 dark:border-gray-700 mb-4">
            <Link
                to="/writing-response"
                className={`tab-link ${location.pathname === "/writing-response" ? "active" : ""}`}
            >
                Responses
            </Link>
            <Link
                to="/writing-response/scored"
                className={`tab-link ${location.pathname === "/writing-response/scored" ? "active" : ""}`}
            >
                Scored Responses
            </Link>
        </div>
    );
};

export default WritingResponseTabs;