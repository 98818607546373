import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'
import ToastContainer from '../common/toast/ToastContainer'
import Footer from './Footer'
import HeaderNav from './HeaderNav'
import SuperAdminSideNav from './SuperAdminSideNav'
import Box from '@mui/joy/Box';
import TeacherSideNav from './TeacherSideNav'

const TeacherLayout = () => {
    const [openSideNav, setOpenSideNav] = useState(true)
    return (
        <Box sx={{ display: 'flex', minHeight: '100dvh' }}>
            <HeaderNav openSideNav={openSideNav} setOpenSideNav={setOpenSideNav} />
            <TeacherSideNav openSideNav={openSideNav}></TeacherSideNav>

            <Box
                component="main"
                className="MainContent ml-[300px]"
                sx={{
                    px: { xs: 2, md: 2 },

                    pt: {
                        xs: 'calc(12px + var(--Header-height))',
                        sm: 'calc(12px + var(--Header-height))',
                        md: 3,
                    },
                    pb: { xs: 2, sm: 2, md: 3 },
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    minWidth: 0,
                    height: '100dvh',
                    gap: 1,
                }}
            >
                <Box className="" sx={{ display: 'flex', alignItems: 'center' }}>
                    <Outlet />
                    <ToastContainer />
                </Box>

            </Box>
        </Box>

    )
}

export default TeacherLayout