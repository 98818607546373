import React from "react";
import { useTranslation } from "react-i18next";
import FeedbackRichTextEditor from "../richTextEditor/FeedbackRichTextEditor";

const FormikFeedbackRichTextEditor = ({
    label,
    value,
    onChange,
    placeholder,
    setRichTextEditorVal,
}) => {
    const { t } = useTranslation();

    const handleChange = (content) => {
        if (onChange) {
            onChange({ target: { name: "feedback", value: content } }); // Mimic event object for consistency
        }
        if (setRichTextEditorVal) {
            setRichTextEditorVal(content);
        }
    };

    return (
        <div>
            <label className="block mb-2 text-sm font-medium" htmlFor="">
                {t(label)}
            </label>
            <FeedbackRichTextEditor
                value={value}
                onChange={handleChange}
                placeholder={placeholder}
            />
        </div>
    );
};

export default FormikFeedbackRichTextEditor;