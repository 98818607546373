import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import APIServices from "../../../httpServices/httpServices";
import { emitSuccessToast, emitErrorToast } from "../../common/toast/ToastContainer";
import { useSelector } from "react-redux";
import FormikFeedbackRichTextEditor from "../../common/form/FormikFeedbackRichTextEditor";

const ViewWritingResponse = () => {
    const { id } = useParams();
    const profile = useSelector((state) => state.user.profile);
    const [response, setResponse] = useState(null);
    const [activeTab, setActiveTab] = useState("task1");
    const [scoring, setScoring] = useState({
        contentOrCoherence: 0,
        vocabulary: 0,
        grammar: 0,
        taskFulfillment: 0,
        when: 0,
        totalScore: 0,
        feedback: "",
    });
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchResponse = async () => {
            const { data, success } = await new APIServices(`writing-response/${id}`).get();
            if (success) {
                setResponse(data);
            } else {
                emitErrorToast("Failed to fetch response.");
            }
        };
        fetchResponse();
    }, [id]);

    const fetchScoring = async (activeTab) => {
        const { data, success } = await new APIServices(`writing-scoring/getOne/byUser`).post({
            writingResponseId: id,
            userId: profile._id,
        });

        if (success && data) {
            const taskScoresField = activeTab === "task1" ? "task1Scores" : "task2Scores";
            const totalScoreField = activeTab === "task1" ? "task1TotalScore" : "task2TotalScore";
            const feedbackField = activeTab === "task1" ? "task1feedback" : "task2feedback";

            setScoring({
                contentOrCoherence: data[taskScoresField]?.contentOrCoherence || 0,
                vocabulary: data[taskScoresField]?.vocabulary || 0,
                grammar: data[taskScoresField]?.grammar || 0,
                taskFulfillment: data[taskScoresField]?.taskFulfillment || 0,
                when: data[taskScoresField]?.when || 0,
                totalScore: data[totalScoreField] || 0,
                feedback: data[feedbackField] || "",
            });
        } else {
            console.log("Failed to fetch scoring data.");
        }
    };

    useEffect(() => {
        fetchScoring(activeTab); // Pass activeTab as an argument
    }, [id, profile._id, activeTab]); // Add activeTab as a dependency

    const handleTabSwitch = async (tab) => {
        setActiveTab(tab); // Update the active tab
        setScoring({
            contentOrCoherence: 0,
            vocabulary: 0,
            grammar: 0,
            taskFulfillment: 0,
            when: 0,
            totalScore: 0,
            feedback: "",
        }); // Reset scoring state temporarily

        try {
            await fetchScoring(tab); // Pass the new tab value to fetchScoring
        } catch (error) {
            console.error("Failed to fetch scoring data:", error);
        }
    };

    const handleScoringChange = (e) => {
        const { name, value } = e.target;

        setScoring((prev) => {
            const updatedScores = {
                ...prev,
                [name]: parseInt(value),
            };

            return {
                ...updatedScores,
                totalScore:
                    updatedScores.contentOrCoherence +
                    updatedScores.vocabulary +
                    updatedScores.grammar +
                    updatedScores.taskFulfillment +
                    updatedScores.when,
            };
        });
    };

    const handleFeedbackChange = (e) => {
        const { value } = e.target;
        setScoring((prev) => ({
            ...prev,
            feedback: value,
        }));
    };

    const handleSubmitScoring = async () => {
        setIsLoading(true);

        const taskScoresField = activeTab === "task1" ? "task1Scores" : "task2Scores";
        const totalScoreField = activeTab === "task1" ? "task1TotalScore" : "task2TotalScore";
        const feedbackField = activeTab === "task1" ? "task1feedback" : "task2feedback";

        const payload = {
            writingResponseId: id,
            teacherUserId: (profile.role === "Superadmin" || profile.role === "Teacher") ? profile._id : null,
            [taskScoresField]: {
                contentOrCoherence: scoring.contentOrCoherence,
                vocabulary: scoring.vocabulary,
                grammar: scoring.grammar,
                taskFulfillment: scoring.taskFulfillment,
                when: scoring.when,
            },
            [totalScoreField]: scoring.totalScore,
            [feedbackField]: scoring.feedback,
        };

        const { success, message } = await new APIServices("writing-scoring").post(payload);
        if (success) {
            emitSuccessToast(message);
            await fetchScoring(activeTab);
        } else {
            emitErrorToast("Failed to submit scoring.");
        }
    };

    if (!response) {
        return <div>Loading...</div>;
    }

    const getTaskData = (task) => (task === "task1" ? response?.writingTask1QuestionId : response?.writingTask2QuestionId);

    const scoreOptions = Array.from({ length: 13 }, (_, i) => (
        <option key={i} value={i}>
            {i}
        </option>
    ));

    return (
        <div className="max-w-6xl mx-auto px-4 py-8">
            <h1 className="text-2xl font-bold mb-6">View Writing Response</h1>
            <div className="flex border-b border-gray-300 mb-6">
                <button
                    onClick={() => handleTabSwitch("task1")}
                    className={`px-4 py-2 font-medium text-lg ${activeTab === "task1" ? "border-b-2 border-blue-500 text-blue-600" : "text-gray-600"}`}
                >
                    Task 1
                </button>
                <button
                    onClick={() => handleTabSwitch("task2")}
                    className={`px-4 py-2 font-medium text-lg ${activeTab === "task2" ? "border-b-2 border-blue-500 text-blue-600" : "text-gray-600"}`}
                >
                    Task 2
                </button>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <div className="col-span-2">
                    <div className="bg-white p-6 rounded-lg shadow-md mb-6">
                        <h2 className="text-xl font-semibold mb-4">Task Details</h2>
                        <p><strong>Question:</strong> {getTaskData(activeTab)?.question}</p>
                        <p className="mt-4 whitespace-pre-wrap"><strong>Scenario:</strong> {getTaskData(activeTab)?.scenario}</p>
                        {activeTab === "task1" && (
                            <div className="mt-4">
                                <strong>Instructions:</strong>
                                <div
                                    className="whitespace-pre-wrap"
                                    dangerouslySetInnerHTML={{ __html: getTaskData(activeTab)?.instructions }}
                                />
                            </div>
                        )}
                        {activeTab === "task2" && (
                            <>
                                <p className="mt-4"><strong>Options:</strong></p>
                                <ul className="list-disc list-inside">
                                    {getTaskData(activeTab)?.options?.map((option, index) => (
                                        <li key={index}>{option.text}</li>
                                    ))}
                                </ul>
                            </>
                        )}
                    </div>

                    <div className="bg-white p-6 rounded-lg shadow-md">
                        <h2 className="text-xl font-semibold mb-4">Response</h2>
                        <pre className="bg-gray-50 p-4 rounded-md whitespace-pre-wrap">
                            {activeTab === "task1" ? response?.task1responseText : response?.task2responseText}
                        </pre>
                        <p className="mt-4"><strong>Word Count:</strong> {activeTab === "task1" ? response?.task1WordCount : response?.task2WordCount}</p>
                        <p className="mt-4"><strong>Submission Time:</strong> {activeTab === "task1" ? new Date(response?.task1SubmissionTime).toLocaleString() : new Date(response?.task2SubmissionTime).toLocaleString()}</p>
                    </div>
                </div>

                <div className="bg-white p-6 rounded-lg shadow-md">
                    <h2 className="text-xl font-semibold mb-4">Scoring</h2>
                    <div className="grid grid-cols-1 gap-4">
                        {[
                            { label: "Content/Coherence", name: "contentOrCoherence" },
                            { label: "Vocabulary", name: "vocabulary" },
                            { label: "Grammar", name: "grammar" },
                            { label: "Task Fulfillment", name: "taskFulfillment" },
                            { label: "When", name: "when" },
                        ].map((field) => (
                            <div key={field.name} className="flex items-center gap-4">
                                <label className="block text-sm font-medium text-gray-700 flex-1">
                                    {field.label} (0-12)
                                </label>
                                <select
                                    name={field.name}
                                    value={scoring[field.name]}
                                    onChange={handleScoringChange}
                                    className="mt-1 block w-24 p-2 border border-gray-300 rounded-md"
                                >
                                    {scoreOptions}
                                </select>
                            </div>
                        ))}
                    </div>

                    <div className="mt-6">
                        <label className="block text-sm font-medium text-gray-700">Total Score</label>
                        <input
                            type="number"
                            value={scoring.totalScore}
                            readOnly
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md bg-gray-100"
                        />
                    </div>

                    <div className="mt-6">
                        <FormikFeedbackRichTextEditor
                            label="Feedback"
                            value={scoring.feedback}
                            onChange={handleFeedbackChange}
                            placeholder="Enter feedback..."
                        />
                    </div>

                    <div className="mt-6">
                        <button
                            onClick={handleSubmitScoring}
                            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                        >
                            Submit Feedback
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewWritingResponse;