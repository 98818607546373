import "./App.css";

import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import UserForm from "./components/container/users/UserForm";
import LayoutDecider from "./components/layouts/LayoutDecider";
import Protected from "./components/pages/Protected";
import Public from "./components/pages/Public";
import RolesCheck from "./components/pages/RolesChek";
import ForgotPassword from "./components/pages/auth/ForgotPassword";
import Login from "./components/pages/auth/Login";
import ProfileChangePassword from "./components/pages/auth/ProfileChangePassword";
import RecoverPassword from "./components/pages/auth/RecoverPassword";
import UserChangePassword from "./components/pages/auth/UserChangePassword";
import Home from "./components/pages/dashboard/Home";
import NotFoundLogList from "./components/pages/logs/notfound/NotFoundLogList";
import NotFound from "./components/pages/notfound/NotFound";
import AddRole from "./components/pages/roles/AddRole";
import EditRole from "./components/pages/roles/EditRole";
import RolesList from "./components/pages/roles/RolesList";
import ViewRole from "./components/pages/roles/ViewRole";
import AddSetting from "./components/pages/setting/AddSetting";
import EditSetting from "./components/pages/setting/EditSetting";
import SettingsList from "./components/pages/setting/SettingsList";
import ViewSetting from "./components/pages/setting/ViewSetting";
import Profile from "./components/pages/users/Profile";
import UsersList from "./components/pages/users/UsersList";
import ViewUser from "./components/pages/users/ViewUser";
// import AddInterventionType from "./components/pages/interventionTypeMaterial/AddInterventionType";
import OwnerAnswerView from "./components/container/ownerResponse/OwnerAnswerView";
import OwnerResponseForm from "./components/container/ownerResponse/OwnerResponseForm";
import FrontUsersList from "./components/pages/frontUsers/FrontUsersList";
import ViewFrontUsers from "./components/pages/frontUsers/ViewFrontUsers";
import AddPost from "./components/pages/post/AddPost";
import EditPost from "./components/pages/post/EditPost";
import PostList from "./components/pages/post/PostList";
import ViewPosts from "./components/pages/post/ViewPosts";
import PrepareQuestion from "./components/pages/prepareQuestion/PrepareQuestion";
import PrepareQuestionForm from "./components/pages/prepareQuestion/PrepareQuestionForm";
import AddQuestion from "./components/pages/question/AddQuestion";
import EditQuestion from "./components/pages/question/EditQuestion";
import QuestionList from "./components/pages/question/QuestionList";
import QuestionTypeReport from "./components/pages/question/QuestionTypeReport";
import ViewQuestion from "./components/pages/question/ViewQuestion";
import AddQuestionGroup from "./components/pages/questionGroup/AddQuestionGroup";
import EditQuestionGroup from "./components/pages/questionGroup/EditQuestionGroup";
import QuestionGroupList from "./components/pages/questionGroup/QuestionGroupList";
import ViewQuestionGroup from "./components/pages/questionGroup/ViewQuestionGroup";
import QuestionResponseForm from "./components/pages/questionResponse.js/QuestionResponseForm";
import ResponseList from "./components/pages/response/ResponseList";
import AddTag from "./components/pages/tag/AddTag";
import EditTag from "./components/pages/tag/EditTag";
import TagList from "./components/pages/tag/TagList";
import ViewTag from "./components/pages/tag/ViewTag";
import CategoryList from "./components/pages/category/CategoryList";
import AddCategory from "./components/pages/category/AddCategory";
import EditCategory from "./components/pages/category/EditCategory";
import ViewCategory from "./components/pages/category/ViewCategory";
import ListeningGroupList from "./components/pages/listeningGroup/ListeningGroupList";
import AddListeningGroup from "./components/pages/listeningGroup/AddListeningGroup";
import EditListeningGroup from "./components/pages/listeningGroup/EditListeningGroup";
import ViewListeningGroup from "./components/pages/listeningGroup/ViewListeningGroup";
import ListeningSetPage from "./components/pages/listeningSet/ListeningSetPage";
import ListeningQuestionForm from "./components/container/listeningQuestion/ListeningQuestionForm";
import EditListeningQuestion from "./components/pages/listeningQuestion/EditListeningQuestion";
import WritingGroupList from "./components/pages/writingGroup/WritingGroupList";
import AddWritingGroup from "./components/pages/writingGroup/AddWritingGroup";
import EditWritingGroup from "./components/pages/writingGroup/EditWritingGroup";
import ViewWritingGroup from "./components/pages/writingGroup/ViewWritingGroup";
import WritingQuestionForm from "./components/container/writingQuestion/WritingQuestionForm";
import EditWritingQuestion from "./components/pages/writingQuestion/EditWritingQuestion";
import ViewWritingQuestion from "./components/pages/writingQuestion/ViewWritingQuestion";
import ViewWritingResponse from "./components/pages/writingResponse/ViewWritingResponse";
import WritingResponseList from "./components/pages/writingResponse/WritingResponseList";
import ScoredWritingResponseList from "./components/pages/writingResponse/ScoredWritingResponseList";
import '@fontsource/inter';
import { GET_USER_PROFILE } from "./redux/sagas/actions";
import { useEffect } from "react";

function App() {
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { profile } = useSelector((state) => state.user);

  console.log({ isLoggedIn })
  useEffect(() => {
    if (profile === null || profile === "undefined") {
      getUserProfile();
    }
    // eslint-disable-next-line
  }, []);

  const getUserProfile = () => {
    const payload = {
      url: "admin-user/my-details",
    };
    dispatch(GET_USER_PROFILE(payload));
  };
  return (
    <Routes>
      {/* AUTHENTICATION ROUTES */}
      <Route
        path="/auth"
        element={
          <Public isLoggedIn={isLoggedIn}>
            <Outlet />
          </Public>
        }
      >
        <Route index element={<Navigate to="login" replace />} />
        <Route path="login" element={<Login />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="recover-password/:token" element={<RecoverPassword />} />
      </Route>

      {/* AUTHENTICATION PASSED ROUTES */}
      <Route
        path="/"
        element={
          <Protected isLoggedIn={isLoggedIn}>
            <LayoutDecider />
          </Protected>
        }
      >
        <Route index element={<Home />} />

        <Route
          path="/admin-users"
          element={<RolesCheck roles={["Superadmin", "Admin", "Teacher"]} />}
        >
          <Route index element={<UsersList />} />
          <Route path="add" element={<UserForm />} />
          <Route path="edit/:id" element={<UserForm />} />
          <Route path="view/:id" element={<ViewUser />} />
          <Route path="change-password" element={<UserChangePassword />} />
        </Route>

        <Route
          path="/users">
          <Route index element={<FrontUsersList />} />
          {/* <Route path="add" element={<UserForm />} /> */}
          {/* <Route path="edit/:id" element={<UserForm />} /> */}
          <Route path="view/:id" element={<ViewFrontUsers />} />
          {/* <Route path="change-password" element={<UserChangePassword />} /> */}
        </Route>

        <Route
          path="/setting"
          element={<RolesCheck roles={["Superadmin", "Admin"]} />}
        >
          <Route index element={<SettingsList />} />
          <Route path="add" element={<AddSetting />} />
          <Route path="edit/:id" element={<EditSetting />} />
          <Route path="view/:id" element={<ViewSetting />} />

          <Route path="role">
            <Route index element={<RolesList />} />
            <Route path="add" element={<AddRole />} />
            <Route path="edit/:id" element={<EditRole />} />
            <Route path="view/:id" element={<ViewRole />} />
          </Route>
        </Route>

        <Route path="/post">
          <Route path=":pagetype" element={<PostList />} />
          <Route path="add" element={<AddPost />} />
          <Route path="edit/:id" element={<EditPost />} />
          <Route path="view/:id" element={<ViewPosts />} />
        </Route>
        <Route path="/tag">
          <Route index element={<TagList />} />
          <Route path="add" element={<AddTag />} />
          <Route path="edit/:id" element={<EditTag />} />
          <Route path="view/:id" element={<ViewTag />} />
        </Route>
        <Route path="/category">
          <Route index element={<CategoryList />} />
          <Route path="add" element={<AddCategory />} />
          <Route path="edit/:id" element={<EditCategory />} />
          <Route path="view/:id" element={<ViewCategory />} />
        </Route>

        <Route path="/profile">
          <Route index element={<Profile />} />
          <Route path="change-password" element={<ProfileChangePassword />} />
        </Route>

        <Route
          path="/logs"
          element={<RolesCheck roles={["Superadmin", "Admin"]} />}
        >
          <Route index element={<Navigate to="not-found-log" />} />
          <Route path="not-found-log">
            <Route index element={<NotFoundLogList />} />
          </Route>
        </Route>

        <Route
          path="/question-group"
          element={<RolesCheck roles={["Superadmin", "Admin", "Teacher"]} />}
        >
          <Route index element={<QuestionGroupList />} />
          <Route path="add" element={<AddQuestionGroup />} />
          <Route path="edit/:id" element={<EditQuestionGroup />} />
          <Route path="view/:id" element={<ViewQuestionGroup />} />
        </Route>

        <Route
          path="/prepare-question"
          element={<RolesCheck roles={["Superadmin", "Admin", "Teacher"]} />}
        >
          {/* <Route index element={<QuestionGroupList />} /> */}
          <Route path="add/:id/:part" element={<PrepareQuestion />} />
          <Route path="detail/:id/:part?" element={<PrepareQuestionForm />} />
        </Route>

        <Route
          path="/question/:id?"
          element={<RolesCheck roles={["Superadmin", "Admin", "Teacher"]} />}
        >
          <Route index element={<QuestionList />} />
          <Route path="add" element={<AddQuestion />} />
          <Route path="edit/:id" element={<EditQuestion />} />
          <Route path="view/:id" element={<ViewQuestion />} />
          <Route path="report/:id" element={<QuestionTypeReport />} />
        </Route>
        <Route path="/question-response">
          <Route path=":id" element={<QuestionResponseForm />} />
        </Route>

        <Route path="/response-list/:quesgrpid?">
          <Route index element={<ResponseList />} />
        </Route>

        <Route path="/response">
          <Route index element={<OwnerResponseForm />} />
        </Route>
        <Route
          path="/user-response/:id"
          element={<RolesCheck roles={["Superadmin", "Admin", "Teacher"]} />}
        >
          <Route index element={<OwnerAnswerView />} />
        </Route>

        <Route
          path="/listening-group"
          element={<RolesCheck roles={["Superadmin", "Admin", "Teacher"]} />}
        >
          <Route index element={<ListeningGroupList />} />
          <Route path="add" element={<AddListeningGroup />} />
          <Route path="edit/:id" element={<EditListeningGroup />} />
          <Route path="view/:id" element={<ViewListeningGroup />} />
        </Route>

        <Route
          path="/listening-set"
          element={<RolesCheck roles={["Superadmin", "Admin", "Teacher"]} />}
        >
          <Route
            path=":id/:partId/:sectionId"
            element={<ListeningSetPage />}
          />
        </Route>

        <Route
          path="/listening-question"
          element={<RolesCheck roles={["Superadmin", "Admin", "Teacher"]} />}
        >
          <Route
            path=":groupId/:id/:partId/:sectionId"
            element={<ListeningQuestionForm />}
          />
          <Route
            path="edit/:questionId/:groupId/:id/:partId/:sectionId"
            element={<EditListeningQuestion />}
          />
        </Route>

        <Route
          path="/writing-group"
          element={<RolesCheck roles={["Superadmin", "Admin", "Teacher"]} />}
        >
          <Route index element={<WritingGroupList />} />
          <Route path="add" element={<AddWritingGroup />} />
          <Route path="edit/:id" element={<EditWritingGroup />} />
          <Route path="view/:id" element={<ViewWritingGroup />} />
        </Route>

        <Route
          path="/writing-question"
          element={<RolesCheck roles={["Superadmin", "Admin", "Teacher"]} />}
        >
          <Route
            path=":groupId/:questionType"
            element={<WritingQuestionForm />}
          />
          <Route
            path="edit/:id"
            element={<EditWritingQuestion />}
          />
          <Route
            path="view/:id"
            element={<ViewWritingQuestion />}
          />
        </Route>

        <Route
          path="/writing-response"
          element={<RolesCheck roles={["Superadmin", "Admin", "Teacher"]} />}
        >
          <Route index element={<WritingResponseList />} />

          <Route
            path="view/:id"
            element={<ViewWritingResponse />}
          />

          <Route
            path="scored"
            element={<ScoredWritingResponseList />}
          />
        </Route>

      </Route>

      {/* ROUTES NOT FOUND */}
      <Route path="*" element={<NotFound />} />

    </Routes>
  );
}

export default App;
