import { ErrorMessage } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { TextError } from "./TextError";
import { Button, IconButton, Tooltip, Sheet, Typography } from '@mui/joy';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';


const SingleAudioUpload = ({ field, form, meta }) => {
    const fileRef = useRef(null);
    const audioRef = useRef(null);

    const { t } = useTranslation();

    const [uploadedAudio, setUploadedAudio] = useState(null);
    const [preview, setPreview] = useState("");
    const [duration, setDuration] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);

    useEffect(() => {
        if (!!field.value && typeof field.value === "string") {
            form.setFieldValue(field.name, "");
            setPreview(process.env.REACT_APP_IMAGE_BASE_URL + field.value);
        }
        // eslint-disable-next-line
    }, [field.value]);

    useEffect(() => {
        if (!uploadedAudio) return;

        const objectUrl = URL.createObjectURL(uploadedAudio);
        setPreview(objectUrl);

        return () => URL.revokeObjectURL(objectUrl);
        // eslint-disable-next-line
    }, [uploadedAudio]);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setUploadedAudio(file);
        form.setFieldValue(field.name, file);
        setDuration(null); // Reset duration when a new file is uploaded
    };

    const handleUpload = () => {
        fileRef.current.click();
    };

    const handleCancel = (e) => {
        e.preventDefault();
        setUploadedAudio(null);
        setPreview("");
        setDuration(null);
        form.setFieldValue(field.name, "");
    };

    const handlePlayPause = () => {
        if (!audioRef.current) return;
        if (isPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    const handleLoadedMetadata = () => {
        if (audioRef.current) {
            setDuration(audioRef.current.duration.toFixed(2)); // Set audio duration
        }
    };

    return (
        <div>
            <div className="relative w-full shadow rounded overflow-hidden">
            <Sheet variant="outlined" sx={{ p: 1, display: 'flex', alignItems: 'center', backgroundColor: 'background.level1' }}>
      {preview && (
        <>
          <audio
            ref={audioRef}
            src={preview}
            controls={false}
            onLoadedMetadata={handleLoadedMetadata}
            style={{ display: 'none' }} // Hide the default controls
          />
          
          <Tooltip title={isPlaying ? "Pause Audio" : "Play Audio"} placement="top">
            <IconButton
              onClick={handlePlayPause}
              sx={{
                backgroundColor: isPlaying ? 'primary.main' : 'secondary.main',
                color: 'white',
                '&:hover': {
                  backgroundColor: isPlaying ? 'primary.dark' : 'secondary.dark',
                },
                borderRadius: '50%',
                p: 2,
                mr: 2,
              }}
            >
              {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
            </IconButton>
          </Tooltip>

          <Tooltip title="Remove Audio" placement="top">
            <IconButton
              onClick={handleCancel}
              sx={{
                backgroundColor: 'error.main',
                color: 'white',
                '&:hover': {
                  backgroundColor: 'error.dark',
                },
                borderRadius: '50%',
                p: 2,
              }}
            >
              <RemoveCircleIcon />
            </IconButton>
          </Tooltip>
        </>
      )}

      {duration && (
        <Typography level="body2" sx={{ ml: 2, color: 'text.secondary' }}>
          Duration: {duration} seconds
        </Typography>
      )}
    </Sheet>
            </div>
            <div className="inline-flex w-full bg-grey-lighter mt-3 relative">
                <label
                    onClick={handleUpload}
                    className="w-64 flex flex-col items-center px-4 py-6 bg-white text-blue rounded-lg shadow-lg uppercase border border-blue-300 cursor-pointer hover:bg-blue-300 hover:text-white"
                >
                    <svg
                        className="w-8 h-8 flex items-center justify-center"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                    >
                        <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                    </svg>
                    <span className="mt-2 text-base leading-normal">
                        {uploadedAudio ? t("Replace Audio") : t("Upload Audio")}
                    </span>
                </label>
                <input
                    name={field.name}
                    ref={fileRef}
                    type="file"
                    accept="audio/*"
                    onChange={handleFileChange}
                    onBlur={form.handleBlur}
                    className={`hidden`}
                />
            </div>
            <ErrorMessage name={field.name} component={TextError} />
        </div>
    );
};

export default SingleAudioUpload;
