import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomTable from "../../common/CustomTable";
import APIServices from "../../../httpServices/httpServices";
import TablePagination from "../../common/table/TablePagination";
import TableEntry from "../../common/table/TableEntry";
import TableSkeleton from "../../common/skeleton/TableSkeleton";
import { LIMIT } from "../../../utilities/constant";
import { useSelector } from "react-redux";
import WritingResponseTabs from "../../common/tabs/WritingResponseTabs";


const ScoredWritingResponseList = ({ }) => {
    const profile = useSelector((state) => state.user.profile);
    const navigate = useNavigate();
    const tableHeading = ["User", "WritingGroup", , "Task1", "task1TotalScore", "Task2", "task2TotalScore", "TotalScore", "Action"];
    const [dataListToShowOnTable, setDataListToShowOnTable] = useState([]);

    //PAGINATION STATE
    const [searchTxt, setsearchTxt] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const limit = LIMIT;

    const [numberOfTotalData, setNumberOfTotalData] = useState(0);
    const [totalPagesNumber, setTotalPageNumber] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        let timeout;
        if (!!searchTxt) {
            timeout = setTimeout(() => {
                getListOfDataToShowOnTable();
            }, 1500);
        } else {
            getListOfDataToShowOnTable();
        }
        return () => clearTimeout(timeout);
        // eslint-disable-next-line
    }, [searchTxt, currentPage]);



    const getListOfDataToShowOnTable = async () => {
        const { data, success, total, totalPages } = await new APIServices(
            `writing-response/list/byLoggedInUser`
        ).post({
            page: currentPage,
            limit: limit,
            search: searchTxt,
            loggedInUserId: profile._id
        });
        if (success) {
            setIsLoading(false);
            setDataListToShowOnTable(
                data.map((request) => ({
                    ...request,
                    User: request?.userId?.email,
                    WritingGroup: request?.writingGroupId?.title,
                    Task1: `${request?.writingTask1QuestionId?.question}`,
                    Task2: `${request?.writingTask2QuestionId?.question}`,
                    task1TotalScore: request?.scoringDetails?.task1TotalScore,
                    task2TotalScore: request?.scoringDetails?.task2TotalScore,
                    TotalScore: request?.scoringDetails?.task1TotalScore + request?.scoringDetails?.task2TotalScore,
                    // ScoreStatus: request?.status
                }))
            );

            setTotalPageNumber(totalPages || 0);
            setNumberOfTotalData(total || 0);
        }
    };


    const handleEdit = (id) => {
        navigate(`/writing-response/edit/${id}`);
    };

    const handleView = (id) => {
        navigate(`/writing-response/view/${id}`)
    };


    const handleTableSearch = (val) => {
        setsearchTxt(val);
    };

    return (
        <section className="dark:bg-gray-800 ">
            <div className="max-w-6xl px-4 py-4 mx-auto lg:py-8 md:px-6">
                <div className="pt-4 rounded shadow bg-white dark:bg-gray-900">
                    <WritingResponseTabs />

                    {isLoading ? (
                        <TableSkeleton />
                    ) : (
                        <>
                            <TableEntry
                                title="All Scored Writing Responses"
                                handleTableSearch={handleTableSearch}
                            />
                            <CustomTable
                                tableData={dataListToShowOnTable}
                                tableHeadingData={tableHeading}
                                handleDelete={false}
                                // handleEdit={handleEdit}
                                handleView={handleView}
                                actionField="_id"
                            />
                            <TablePagination
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                totalPagesNumber={totalPagesNumber}
                                numberOfTotalData={numberOfTotalData}
                                limit={limit}
                            />
                        </>
                    )}
                </div>
            </div>
        </section>
    );
};

export default ScoredWritingResponseList;
