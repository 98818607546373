import React, { useMemo, useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import APIServices from "../../../httpServices/httpServices";
import { emitErrorToast } from "../toast/ToastContainer";

const FeedbackRichTextEditor = ({ value, onChange, placeholder, setRichTextEditorVal }) => {
    const quillRef = useRef();

    const imageHandler = (e) => {
        const editor = quillRef.current.getEditor();
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();

        input.onchange = async () => {
            const file = input.files[0];
            if (/^image\//.test(file.type)) {
                const formData = new FormData();
                formData.append("file", file);
                const { success, data } = await new APIServices("/file/upload").post(
                    formData
                );

                if (success) {
                    const url = data;
                    editor.insertEmbed(editor.getSelection(), "image", url);
                }
            } else {
                emitErrorToast("You could only upload images.");
            }
        };
    };

    const modules = useMemo(
        () => ({
            toolbar: {
                container: [
                    ["bold", "italic", "underline", "strike"], // toggled buttons
                    ["blockquote"],
                    [{ header: 1 }, { header: 2 }], // custom button values
                    [{ list: "ordered" }, { list: "bullet" }],
                    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                    [{ direction: "rtl" }], // text direction
                    [
                        {
                            color: [
                                "#000000",
                                "#e60000",
                                "#ff9900",
                                "#ffff00",
                                "#008a00",
                                "#0066cc",
                                "#9933ff",
                                "#ffffff",
                                "#facccc",
                                "#ffebcc",
                                "#ffffcc",
                                "#cce8cc",
                                "#cce0f5",
                                "#ebd6ff",
                                "#bbbbbb",
                                "#f06666",
                                "#ffc266",
                                "#ffff66",
                                "#66b966",
                                "#66a3e0",
                                "#c285ff",
                                "#888888",
                                "#a10000",
                                "#b26b00",
                                "#b2b200",
                                "#006100",
                                "#0047b2",
                                "#6b24b2",
                                "#444444",
                                "#5c0000",
                                "#663d00",
                                "#666600",
                                "#003700",
                                "#002966",
                                "#3d1466",
                            ],
                        },
                    ][{ size: ["small", false, "large", "huge"] }], // custom dropdown
                    [{ header: [1, 2, 3, 4, 5, 6, false] }],

                    ["link", "image", "video"],
                    ["clean"],
                ],
                handlers: {
                    image: imageHandler,
                },
            },
        }),
        []
    );

    const formats = [
        "header",
        "font",
        "size",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "video",
    ];

    const handleChange = (content) => {
        if (onChange) {
            onChange(content); // Pass the content directly to the onChange prop
        }
        if (setRichTextEditorVal) {
            setRichTextEditorVal(content);
        }
    };

    return (
        <div className="h-[500]">
            <ReactQuill
                theme="snow"
                placeholder={placeholder}
                ref={quillRef}
                modules={modules}
                formats={formats}
                value={value}
                onChange={handleChange}
            />
        </div>
    );
};

export default FeedbackRichTextEditor;