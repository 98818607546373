import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import APIServices from "../../../httpServices/httpServices";
import DeleteModal from "../../common/modals/DeleteModal";
import { emitSuccessToast } from "../../common/toast/ToastContainer";
import DataView from "../../common/viewDetail/DataView";
import WritingQuestionList from "../writingQuestion/WritingQuestionList";


const ViewWritingGroup = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [showModal, setShowModal] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (id) {
            getDetailView();
        }
        // eslint-disable-next-line
    }, [id]);

    // Fetch User Data
    const getDetailView = async () => {
        setLoading(true);
        const { data, success } = await new APIServices(
            `writing-group/${id}`
        ).get();
        if (success) {
            setData(data);
            setLoading(false);
        } else {
            setLoading(false);
        }
    };



    const handleShowDeleteModal = (id) => {
        setShowModal(true);
    };


    const handleDeleteRole = async () => {
        const { message, success } = await new APIServices(
            `writing-group/${id}`
        ).delete();
        if (success) {
            setShowModal(false);
            emitSuccessToast(message);
            navigate("/writing-group");
        }
    };

    const {
        _id,
        title,
        detail,
        status,
        level,
        writingQuestions = []
    } = data;

    // Check if EmailWriting question exists
    const hasEmailWritingQuestion = writingQuestions.some(
        question => question.questionType === "EmailWriting"
    );

    // Check if EssayWriting question exists
    const hasEssayWritingQuestion = writingQuestions.some(
        question => question.questionType === "EssayWriting"
    );


    return (

        <>
            {loading && <div>Loading...</div>}
            <div className="max-w-6xl px-4 py-4 mx-auto lg:py-8 md:px-6">
                <div className="flex justify-between mt-5 mb-4 items-end">
                    <h2 className="font-bold text-lg"> Writing Group Information </h2>

                </div>
                <div className="flex items-center justify-between">
                    <DataView label="title" value={title} />
                    <div className="flex items-center">
                        <div
                            className={`rounded-full w-3 h-3 bg-green-700 mr-2 ${status === "Active" ? "bg-green-700" : "bg-red-700"
                                }`}
                        ></div>
                        <span className="text-sm"> {status} </span>
                    </div>
                    {status === "Inactive" && (
                        <button
                            onClick={() => handleShowDeleteModal(_id)}
                            className=" flex items-center px-4 py-2.5 bg-red-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-600"
                        >
                            Delete
                        </button>
                    )}
                </div>

                <div className="shadow bg-white border-gray-500 p-8 rounded">
                    <div className="flex flex-wrap">
                        <DataView label="level" value={level} />
                        <DataView label="detail" value={detail} />

                    </div>
                </div>

                {showModal && (
                    <DeleteModal handleDelete={handleDeleteRole} close={setShowModal} />
                )}

                <div className="flex gap-4 mt-4">
                    {!hasEmailWritingQuestion && (
                        <Link
                            to={`/writing-question/${_id}/EmailWriting`}
                            className="flex items-center px-4 py-2.5 bg-primary text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-700 mb-5"
                        >
                            Add Task 1 Question
                        </Link>
                    )}

                    {!hasEssayWritingQuestion && (
                        <Link
                            to={`/writing-question/${_id}/EssayWriting`}
                            className="flex items-center px-4 py-2.5 bg-primary text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-700 mb-5"
                        >
                            Add Task 2 Question
                        </Link>
                    )}

                </div>
                <WritingQuestionList writingGroupId={_id} />
            </div>
        </>
    );
};

export default ViewWritingGroup;
