import * as yup from 'yup';

let RoleValidationSchema = yup.object().shape({
    status: yup.boolean(),
    title: yup.string().max(20, 'Must be 15 characters or less').required('Required !'),
    uniqueName: yup.string().max(20, 'Must be 15 characters or less').required('Required !'),
    permissionValue: yup.array().required('Required !'),

});


export default RoleValidationSchema