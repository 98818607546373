import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import HomeSkeleton from "../../common/skeleton/HomeSkeleton";
import AdminHome from "./AdminHome";

const Home = () => {
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);
  const { profile } = useSelector((state) => state.user);

  const getComponent = useCallback(() => {
    switch (profile?.role) {
      case "Superadmin":
      case "Admin":
      case "Teacher":
        return <AdminHome />;

      default:
        return;
    }
    // eslint-disable-next-line
  }, [profile?.role]);

  return (
    <>
      <section className="px-4 py-4 lg:px-6 lg:py-6">
        {isLoading ? <HomeSkeleton /> : <>{getComponent()}</>}
      </section>
    </>
  );
};

export default Home;
