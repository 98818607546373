import { ErrorMessage, Field } from "formik";
import React from "react";
import RichTextEditor from "../richTextEditor/RichTextEditor";
import { TextError } from "../TextError";
import { useTranslation } from "react-i18next";

const FormikRichTextEditor = ({
  label,
  name,
  setRichTextEditorVal,
  validation,
}) => {

  // console.log(first)
  const { t } = useTranslation();

  return (
    <div>
      <label
        className="block mb-2 text-sm font-medium "
        htmlFor=""
      >
        {t(label)}
      </label>
      <Field name={name} validate={validation}>
        {({ field, form, meta }) => {
          return (
            <RichTextEditor
              field={field}
              form={form}
              meta={meta}
              setRichTextEditorVal={setRichTextEditorVal}
            />
          );
        }}
      </Field>
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
};

export default FormikRichTextEditor;
