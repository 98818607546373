import { Form, Formik } from "formik";
import React from "react";
import APIServices from "../../../httpServices/httpServices";
import ChangeProfilePasswordValidation from "../../../validation/ChangeProfilePasswordValidation";
import FormikTextField from "../../common/form/FormikTextField";
import {
  emitErrorToast,
  emitSuccessToast,
} from "../../common/toast/ToastContainer";
import { t } from "i18next";
import { logout } from "../../../redux/features/auth/authSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const ProfileChangePassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const initialValues = {
    oldPassword: "",
    password: "",
    confirmPassword: "",
  };
  const handleFormikFormSubmit = async (values, actions) => {
    const { setFieldError } = actions;
    const { success, message } = await new APIServices(
      `admin-user/profile/change-password`
    ).post(values);
    if (success) {
      emitSuccessToast("password changed successfully");
      dispatch(logout({ url: "auth/logout" }));
      navigate("/auth/login");
    } else {
      if (message === "Incorrect old password") {
        setFieldError("oldPassword", message);
      } else {
        emitErrorToast(message);
      }
    }
  };

  return (
    <section className="bg-gray-100">
      <div className="max-w-md mx-auto">
        <div className="flex items-center justify-center h-screen ">
          <div className=" rounded shadow w-full bg-white p-10">
            <div className="px-8 mb-4 text-center">
              <h3 className="pt-4 mb-2 text-2xl">{t('Change Your password')}</h3>
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={ChangeProfilePasswordValidation}
              onSubmit={handleFormikFormSubmit}
              enableReinitialize
            >
              {(formik) => (
                <Form>
                  <div className="mb-6">
                    <FormikTextField
                      label="Old password *"
                      name="oldPassword"
                      type="password"
                      formik={formik}
                    />
                  </div>
                  <div className="mb-6">
                    <FormikTextField
                      label="New password *"
                      name="password"
                      type="password"
                      formik={formik}
                    />
                  </div>
                  <div className="mb-6">
                    <FormikTextField
                      label="Confirm password *"
                      name="confirmPassword"
                      type="password"
                      formik={formik}
                    />
                  </div>
                  {/* Submit */}
                  <div className="mb-6 text-center">
                    <button
                      className="w-full px-4 py-2 font-bold text-white bg-blue-600 rounded-full hover:bg-blue-500 focus:outline-none focus:shadow-outline"
                      type="submit"
                    >
                      {t('Reset password')}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProfileChangePassword;
