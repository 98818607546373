import * as yup from 'yup';

let RecoverPasswordValidationSchema = yup.object().shape({
    password: yup.string().min(8, 'Your password is too short.').max(30, 'Your password is too long.').required('Required !'),
    confirmPassword: yup.string().min(8, 'Your password is too short.').max(30, 'Your password is too long.').oneOf([yup.ref('password'), null], 'Passwords must match').required('Required !'),


});


export default RecoverPasswordValidationSchema