import React from "react";
import { useSelector } from "react-redux";
import SuperAdminLayout from "./SuperAdminLayout";
import UsersLayout from "./UsersLayout";
import TeacherLayout from "./TeacherLayout";

// This component decide layout according to user role
const LayoutDecider = () => {
  const { profile } = useSelector((state) => state.user);
  console.log({ profile })
  switch (profile?.role) {
    case "Superadmin":
    case "Admin":
      return <SuperAdminLayout />;
    case "Teacher":
      return <TeacherLayout />;
    default:
      return <UsersLayout />;

  }
};

export default LayoutDecider;
