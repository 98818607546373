import DOMPurify from "dompurify";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import DropNav from "../common/DropNav";
import DropNavLink from "../common/DropNavLink";

export const NavLink = ({ path, title, svg }) => {
  const { pathname } = useLocation();

  return (
    <li>
      <Link
        to={path}
        className={`${pathname.split("/")[1] === path ? "bg-primary text-white" : ""
          } flex items-center px-4 py-4 mx-2 rounded-xl text-gray-700  dark:hover:bg-gray-800 group hover:text-white hover:bg-primary transition-all`}
      >
        <span
          className="mr-2 "
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(svg) }}
        />
        <span> {title} </span>
      </Link>
    </li>
  );
};

const UserSideNav = ({ openSideNav }) => {
  const { t } = useTranslation();
  return (
    <div className="relative lg:block navbar-menu">
      <nav
        className={`${openSideNav ? "w-0" : "w-[280px] hidden"
          }  fixed top-0  transition-all lg:mt-0 mt-16 left-0 bottom-0 flex flex-col w-[280px] shadow bg-white overflow-hidden z-50`}
        id="sidenav"
      >
        <div className="flex items-center w-full px-4 pt-4 pb-4 border-b border-gray-300 dark:border-gray-700">
          <a href="/" className="font-bold">
            {/* <img
              src="../../assets/locationIcon.png"
              alt=""
              className="object-contain w-28"
            /> */}
            {/* <CustomImage name="toplogo.svg" /> */}
            Celpip
          </a>
        </div>
        <div className="h-screen pb-6 mt-4 overflow-x-hidden overflow-y-auto">
          <ul className="mb-8 text-sm ">
            <NavLink
              title={t("Dashboard")}
              path="/"
              svg='<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-lines-fill" viewBox="0 0 16 16">
                <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2z"/>
                </svg>'
            />

            {/* <DropNav
              title={t("Settting")}
              svg='<svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-gear"
              viewBox="0 0 16 16"
            >
              <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z" />
              <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z" />
            </svg>'
            >
              <DropNavLink title={t("Users")} path="users" />
              <DropNavLink title={t("role")} path="setting/role" />
              <DropNavLink title={t("Other")} path="setting" />
            </DropNav> */}
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default UserSideNav;
