import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomTable from "../../common/CustomTable";
import APIServices from "../../../httpServices/httpServices";
import TablePagination from "../../common/table/TablePagination";
import TableEntry from "../../common/table/TableEntry";
import TableSkeleton from "../../common/skeleton/TableSkeleton";
import { LIMIT } from "../../../utilities/constant";
import moment from "moment";
import DataTable from "../../common/DataTable";
import { Box } from "@mui/material";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { Badge } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

const ListeningGroupList = () => {
  const navigate = useNavigate();
  const [filterState, setFilterState] = useState({});
  const [debouncedFilterState, setDebouncedFilterState] = useState({});
  // Table headings for verified and unverified users
  const tableHeading = [
    {
      accessorKey: "title",
      header: "Title",
      cell: (info) => {
        return info.getValue();
      },
    },
    {
      accessorKey: "status",
      header: "Status",
      cell: (info) => {
        return info.getValue();
      },
      meta: { disableFilter: true },
    },
    {
      accessorKey: "status",
      header: "Status",
      cell: ({ getValue }) => {
        const status = getValue();
        return (
          <span>
            {status === "Active" ? (
              <Box sx={{display: "inline-flex",border: "1px solid green", bgcolor: "green", alignItems: "center", padding: "4px 8px", borderRadius: "20px" }}>
                <CheckCircleIcon style={{ color: "white", marginRight: "4px" }} />
                <span style={{ color: "white", fontSize: "12px" }}> Active</span>
              </Box>
            ) : (
              <Box sx={{display: "inline-flex",border: "1px solid red", bgcolor: "red", alignItems: "center", padding: "4px 8px", borderRadius: "20px" }}>
                <CancelIcon style={{ color: "white", marginRight: "4px" }} />
                <span style={{ color: "white", fontSize: "12px" }}> In Active</span>
              </Box>
            )}
          </span>
        );
      },
      meta: { disableFilter: true },
    },
    {
      accessorKey: "action",
      header: "Action",
      cell: ({ row }) => renderActions(row),
      meta: { disableFilter: true },
    },
  ];
  const [dataListToShowOnTable, setDataListToShowOnTable] = useState([]);

  //PAGINATION STATE
  const [searchTxt, setsearchTxt] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const limit = LIMIT;

  const [numberOfTotalData, setNumberOfTotalData] = useState(0);
  const [totalPagesNumber, setTotalPageNumber] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedFilterState(filterState);
    }, 500); // Adjust debounce time as needed

    return () => clearTimeout(timeout);
  }, [filterState]);
  useEffect(() => {
    let timeout;
    if (!!searchTxt) {
      timeout = setTimeout(() => {
        getListOfDataToShowOnTable();
      }, 1500);
    } else {
      getListOfDataToShowOnTable();
    }
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [searchTxt, currentPage]);

  const getListOfDataToShowOnTable = async () => {
    const { data, success, total, totalPages } = await new APIServices(
      "listening-group/list"
    ).post({
      page: currentPage,
      limit: limit,
      search: searchTxt,
    });

    if (success) {
      setIsLoading(false);
      setDataListToShowOnTable(
        data.map((request) => ({
          ...request,
          title: request.title,
        }))
      );
      setTotalPageNumber(totalPages || 0);
      setNumberOfTotalData(total || 0);
    }
  };

  const renderActions = (row) => (
    <div className="flex gap-2">
      <button
        onClick={() => handleView(row.original._id)}
        className="text-blue-500">
        View
      </button>
      <button
        onClick={() => handleEdit(row.original._id)}
        className="text-green-500">
        Edit
      </button>
    </div>
  );

  const handleFilterChange = (columnId, value) => {
    setFilterState((prev) => ({
      ...prev,
      [columnId]: value,
    }));
  };

  const handleEdit = (id) => {
    navigate(`/listening-group/edit/${id}`);
  };

  const handleView = (id) => {
    navigate(`/listening-group/view/${id}`);
  };

  const navigateToAddNew = () => {
    navigate("/listening-group/add");
  };

  const handleTableSearch = (val) => {
    setsearchTxt(val);
  };

  const handlePreviewQuestion = (id, title) => {
    navigate(`/prepare-listening/add/${id}/1?title=${title}`);
  };

  return (
    <Box
      component="main"
      className="MainContent"
      sx={{
        pt: {
          xs: "calc(12px + var(--Header-height))",
          sm: "calc(12px + var(--Header-height))",
          md: 3,
        },
        pb: { xs: 2, sm: 2, md: 3 },
        flex: 1,
        display: "flex",
        flexDirection: "column",
        minWidth: 0,
        height: "100dvh",
        gap: 1,
      }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={<ChevronRightRoundedIcon fontSize="sm" />}
          sx={{ pl: 0 }}>
          <Link
            underline="none"
            color="neutral"
            href="#some-link"
            aria-label="Home">
            <HomeRoundedIcon />
          </Link>
          <Typography color="primary" sx={{ fontWeight: 500, fontSize: 12 }}>
            Listening
          </Typography>
        </Breadcrumbs>
      </Box>
      <Box
        sx={{
          display: "flex",
          mb: 1,
          gap: 1,
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "start", sm: "center" },
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}>
        <div className="w-full">
          <div className="pt-4 dark:bg-gray-900">
            {isLoading ? (
              <TableSkeleton />
            ) : (
              <>
                <TableEntry
                  title="List of Listening Groups"
                  navigate={navigateToAddNew}
                  handleTableSearch={handleTableSearch}
                />
                {/* <CustomTable
                                tableData={dataListToShowOnTable}
                                tableHeadingData={tableHeading}
                                handleDelete={false}
                                handleEdit={handleEdit}
                                handleView={handleView}
                                // handlePreviewQuestion={handlePreviewQuestion}
                                actionField="_id"
                            /> */}
                <DataTable
                  columns={tableHeading}
                  data={dataListToShowOnTable}
                  onFilterChange={handleFilterChange}
                />
                <TablePagination
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  totalPagesNumber={totalPagesNumber}
                  numberOfTotalData={numberOfTotalData}
                  limit={limit}
                />
              </>
            )}
          </div>
        </div>
      </Box>
    </Box>
  );
};

export default ListeningGroupList;
