import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import APIServices from "../../../httpServices/httpServices";
import WritingQuestionForm from "../../container/writingQuestion/WritingQuestionForm";

const EditWritingQuestion = () => {
    const { id } = useParams();
    const [editData, setEditData] = useState({});

    const getDetail = async () => {
        const { data, success } = await new APIServices(`writing-question/${id}`).get();
        if (success) {
            setEditData({
                ...data,
                status: data?.status === "Active" ? true : false,
            });
        }
    };
    useEffect(() => {
        !!id && getDetail();
        // eslint-disable-next-line
    }, [id]);

    return <WritingQuestionForm editData={editData} />;
};

export default EditWritingQuestion;
