import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomTable from "../../common/CustomTable";
import APIServices from "../../../httpServices/httpServices";
import TablePagination from "../../common/table/TablePagination";
import TableEntry from "../../common/table/TableEntry";
import TableSkeleton from "../../common/skeleton/TableSkeleton";
import { LIMIT } from "../../../utilities/constant";


const WritingQuestionList = ({ writingGroupId }) => {
    const navigate = useNavigate();
    const tableHeading = ["QuestionNo", "questionType", "question", "wordLimit", "timeLimit", "status", "Action"];
    const [dataListToShowOnTable, setDataListToShowOnTable] = useState([]);

    //PAGINATION STATE
    const [searchTxt, setsearchTxt] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const limit = LIMIT;

    const [numberOfTotalData, setNumberOfTotalData] = useState(0);
    const [totalPagesNumber, setTotalPageNumber] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        let timeout;
        if (!!searchTxt) {
            timeout = setTimeout(() => {
                getListOfDataToShowOnTable();
            }, 1500);
        } else {
            getListOfDataToShowOnTable();
        }
        return () => clearTimeout(timeout);
        // eslint-disable-next-line
    }, [searchTxt, currentPage, writingGroupId]);



    const getListOfDataToShowOnTable = async () => {
        const { data, success, total, totalPages } = await new APIServices(
            `writing-question/list`
        ).post({
            page: currentPage,
            limit: limit,
            search: searchTxt,
            writingGroupId: writingGroupId
        });

        if (success) {
            setIsLoading(false);
            setDataListToShowOnTable(
                data.map((request) => ({
                    ...request,
                    QuestionNo: request?.orderBy
                }))
            );

            setTotalPageNumber(totalPages || 0);
            setNumberOfTotalData(total || 0);
        }
    };


    const handleEdit = (id) => {
        navigate(`/writing-question/edit/${id}`);
    };

    const handleView = (id) => {
        navigate(`/writing-question/view/${id}`)
    };
    const navigateToAddNew = () => {
        navigate(`/writing-question/${writingGroupId}`);
    };

    const handleTableSearch = (val) => {
        setsearchTxt(val);
    };

    return (
        <section className="dark:bg-gray-800 ">
            <div className="max-w-6xl px-4 py-4 mx-auto lg:py-8 md:px-6">
                <div className="pt-4 rounded shadow bg-white dark:bg-gray-900">
                    {isLoading ? (
                        <TableSkeleton />
                    ) : (
                        <>
                            <TableEntry
                                title="List of Writing Questions"
                                navigate={!writingGroupId ? navigateToAddNew : ""}
                                handleTableSearch={handleTableSearch}
                            />
                            <CustomTable
                                tableData={dataListToShowOnTable}
                                tableHeadingData={tableHeading}
                                handleDelete={false}
                                handleEdit={handleEdit}
                                handleView={handleView}
                                actionField="_id"
                            />
                            <TablePagination
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                totalPagesNumber={totalPagesNumber}
                                numberOfTotalData={numberOfTotalData}
                                limit={limit}
                            />
                        </>
                    )}
                </div>
            </div>
        </section>
    );
};

export default WritingQuestionList;
