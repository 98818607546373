import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import APIServices from "../../../httpServices/httpServices";
import listeningSetValidationSchema from "../../../validation/ListeningSetValidation";
import SaveButton from "../../common/button/SaveButton";
import FormikImageField from "../../common/form/FormikImageField";
import FormikTextField from "../../common/form/FormikTextField";
import FormikSelectSingleField from "../../common/form/FormikSelectSingleField";
import {
  emitErrorToast,
  emitSuccessToast,
} from "../../common/toast/ToastContainer";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import FormikAudioField from "../../common/form/FormikAudioField";
import FormikVideoField from "../../common/form/FormikVideoField";
import FormikSwitchField from "../../common/form/FormikSwitchField";
import ListeningQuestionList from "../listeningQuestion/ListeningQuestionList";
import CircularProgress from "@mui/material/CircularProgress";
import {
    Box,
    Button,
    Tab,
    TabList,
    Tabs,
    Typography,
    Stack,
    Container,
    Card,
    CardContent,
    Grid,
    Sheet,
    IconButton,
    Tooltip,
  } from '@mui/joy';
  import PlayCircleIcon from '@mui/icons-material/PlayCircle';
  import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
  
  

const ListeningSetPage = () => {
  const { id, partId, sectionId } = useParams(); // Read part and section from URL params
  const part = partId.split("-")[1];
  const section = sectionId.split("-")[1];
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const title = searchParams.get("title") || "Listening Practice";

  const [activePart, setActivePart] = useState(parseInt(part) || 1); // Tracks the active part
  const [activeSection, setActiveSection] = useState(parseInt(section) || 1); // Tracks the active section
  const [listeningSetId, setListeningSetId] = useState("");

  // Define sections for each part
  const sectionsConfig = {
    1: 8,
    2: 5,
    3: 6,
    4: 1,
    5: 1,
    6: 1,
  };

  // Handle URL navigation
  const handleNavigation = (newPart, newSection) => {
    setActivePart(newPart);
    setActiveSection(newSection);
    const encodedTitle = encodeURIComponent(title); // Encode title for URL safety
    navigate(
      `/listening-set/${id}/part-${newPart}/section-${newSection}?title=${encodedTitle}`
    );
  };

  // Set active part and section from URL on load
  useEffect(() => {
    setActivePart(parseInt(part) || 1);
    setActiveSection(parseInt(section) || 1);
  }, [part, section]);

  const initialValues = {
    listeningGroup: id,
    questionType: activePart <= 3 ? "Single" : "Group",
    instruction: "",
    instructionImage: null,
    audio: null,
    video: null,
    duration: "",
    preparationFlag: false,
    part: activePart,
    section: activeSection,
  };

  const [form, setForm] = useState(initialValues);
  const [isLoading, setIsLoading] = useState(true);

  const questionType = ["Single", "Group"];
  const questionTypeOptions = questionType.map((d) => ({ value: d, label: d }));

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const { success, data } = await new APIServices(
          "listening-set/fetch/by-group-part-section"
        ).post({
          listeningGroup: id,
          part: activePart,
          section: activeSection,
        });
        if (success && data) {
          setListeningSetId(data._id);
          setForm((prev) => ({
            ...prev,
            ...data,
          }));
        } else {
          setListeningSetId(null);
          setForm(initialValues); // Reset to initial values if no data
        }
      } catch (error) {
        console.error("Error fetching Listening Set data", error);
        setListeningSetId(null); // Error means no valid listeningSetId
        setForm(initialValues); // Reset to initial values
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id, activePart, activeSection]);

  // Handle Form Submission
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const formData = new FormData();

      Object.keys(values).forEach((key) => {
        const value = values[key];
        if (value instanceof File) {
          formData.append(key, value); // Append files directly
        } else {
          formData.append(key, value);
        }
      });

      const { success, data, message } = await new APIServices(
        "listening-set"
      ).post(formData, { headers: { "Content-Type": "multipart/form-data" } });

      if (success) {
        emitSuccessToast("Listening Set saved successfully!");
        navigate(
          `/listening-question/${id}/${data._id}/part-${activePart}/section-${activeSection}`
        );
        resetForm();
      } else {
        emitErrorToast(message || "Error occurred while saving.");
      }
    } catch (error) {
      emitErrorToast("Failed to save Listening Set.");
    } finally {
      setSubmitting(false);
    }
  };

  if (isLoading) {
    return (
      <Box sx={{ display: "flex" }}>
        <CircularProgress />
      </Box>
    ); // Display a loading state while fetching data
  }

  return (
 

      <Container maxWidth="lg">
        <Typography level="h2" fontWeight="bold" sx={{ mb: 2 }}>
          Listening Set: {title}
        </Typography>
      
        {/* Parts Navigation */}
        <Tabs
          value={activePart - 1}
          onChange={(event, newValue) => handleNavigation(newValue + 1, 1)}
          sx={{ mb: 3 }}
        >
          <TabList variant="soft" sx={{ borderBottom: '1px solid', borderColor: 'neutral.outlinedBorder' }}>
            {[1, 2, 3, 4, 5, 6].map((part) => (
              <Tab
                key={part}
                sx={{
                  borderTopLeftRadius: '8px',
                  borderTopRightRadius: '8px',
                  borderBottom: activePart === part ? '2px solid' : 'none',
                  borderColor: activePart === part ? 'primary.outlinedBorder' : 'neutral.outlinedBorder',
                  minWidth: 'auto',
                  px: 2,
                  py: 1,
                }}
              >
                Part {part}
              </Tab>
            ))}
          </TabList>
        </Tabs>
      
        {/* Section Navigation */}
        <Stack direction="row" spacing={1} sx={{ mb: 3 }}>
          {[...Array(sectionsConfig[activePart]).keys()].map((_, index) => {
            const newSection = index + 1;
            return (
              <Button
                key={newSection}
                variant={activeSection === newSection ? 'solid' : 'outlined'}
                color={activeSection === newSection ? 'primary' : 'neutral'}
                onClick={() => handleNavigation(activePart, newSection)}
              >
                Section {newSection}
              </Button>
            );
          })}
        </Stack>
      
        {/* 2-Column Layout (Right: 60%, Left: 40%) */}
        <Grid container spacing={3}>
          {/* Left Column: Listening Questions (40%) */}
     
          {/* Right Column: Form (60%) */}
          <Grid xs={12} md={6}>
            <Formik
              initialValues={form}
              validationSchema={listeningSetValidationSchema}
              onSubmit={handleSubmit}
              enableReinitialize
            >
              {(formik) => (
                <Card variant="outlined">
                  <CardContent>
                    <Stack spacing={2}>
                      <FormikSelectSingleField
                        label="Select Question Type"
                        name="questionType"
                        options={questionTypeOptions}
                        handleSelectSingle={(selectedOption) =>
                          formik.setFieldValue('questionType', selectedOption.value)
                        }
                      />
      
                      <FormikTextField
                        label="Instruction"
                        name="instruction"
                        as="textarea"
                        placeholder="Please write some instruction"
                        formik={formik}
                      />
      
                      <FormikImageField label="Instruction Image" name="instructionImage" />
      
                      <FormikAudioField label="Audio File" name="audio" />
      
                      {activePart === 5 && <FormikVideoField label="Video File" name="video" />}
      
                      <FormikTextField
                        label="Duration (in seconds)"
                        name="duration"
                        placeholder="e.g., 120"
                        formik={formik}
                      />
      
                      <FormikSwitchField label="Preparation Flag" name="preparationFlag" />
      
                      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <SaveButton isSubmitting={formik.isSubmitting} label="Save" />
                      </Box>
                    </Stack>
                  </CardContent>
                </Card>
              )}
            </Formik>
          </Grid>


          <Grid xs={12} md={6}>
            <Sheet
              variant="outlined"
              sx={{
                
                borderRadius: 'sm',
              }}
            >
   
      
              <ListeningQuestionList
                listeningSetId={listeningSetId}
                listeningGroupId={id}
                part={activePart}
                section={activeSection}
                questionType={activePart <= 3 ? 'Single' : 'Group'}
                renderQuestionRow={({ question, audioUrl }) => (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                      borderBottom: '1px solid',
                      borderColor: 'neutral.outlinedBorder',
                      py: 1,
                    }}
                    key={question.id}
                  >
                    <Typography>{question.text}</Typography>
                    <Stack direction="row" spacing={1}>
                      {audioUrl && (
                        <IconButton
                          variant="soft"
                          size="sm"
                          color="primary"
                          onClick={() => window.open(audioUrl, '_blank')}
                        >
                          <PlayCircleIcon />
                        </IconButton>
                      )}
      
                      {question.url && (
                        <Tooltip title={question.url} variant="outlined" placement="top">
                          <IconButton size="sm" color="neutral">
                            <HelpOutlineIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Stack>
                  </Stack>
                )}
              />
            </Sheet>
          </Grid>
      
        </Grid>
      </Container>
      
      
  );
};

export default ListeningSetPage;
